import axios from "axios";
import { cmsURL } from "../constants/app-constants";
import axiosClient from "./api-handlers";
import axiosMHClient from "./second-api-handlers";

function getJWTtoken() {
  let token = localStorage.getItem("callKey");
  if (typeof window !== "undefined") {
    return token;
  } else if (token === undefined) {
    return null;
  } else {
    return null;
  }
}

let JWTresponse = getJWTtoken();

// const getHeaders = () => {
//   if (JWTresponse === null) {
//     return {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//   } else {
//     return {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${JWTresponse}`,
//       },
//     };
//   }
// };

// let Header = getHeaders();

const axiosCMS = axios.create({
  baseURL: cmsURL,
});

axiosCMS.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

export default axiosCMS;



export async function requestCallback(payload) {
  const response = await axiosCMS.post(
    `/store_request`,

    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}
export async function doctorFormData(payload) {
  const response = await axiosMHClient.post(
    `${cmsURL}store_formdata`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}

// api specialist

export async function specialistAppointment(slug) {
  const response = await axiosClient.post(
    `${cmsURL}get_location_pages?slug=${slug}`
  );
  return response;
}
export async function leadRequestCallback(payload) {
  const response = await axiosMHClient.post(
    `${cmsURL}store_requestcallback`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}


export async function careerFormData(payload) {
  
  const response = await axiosClient.post(
    `${cmsURL}store_careerformdata`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        // authorization: `${apitoken}`,
      },
    }
  );
  return response;
}

export async function getMetaData(url) {
  const response = await axiosClient.post(
    `${cmsURL}all_meta_data?url=${url}`
  );
  return response;
}

export async function getdiagnostics(slug) {
  const response = await axiosClient.post(
    `${cmsURL}get_diagnostics_pages?slug=${slug}`
  );
  return response;
}

/// Home care service
export async function getHomecareData(slug) {
  const response = await axiosClient.post(
    `${cmsURL}get_homecare_data?slug=${slug}`
  );
  return response;
}


export async function orderMedicineNew(payload) {
  const response = await axiosClient.post(
    // `${cmsURL}store_ordermedician`,
    `${cmsURL}store_ordermedician`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        // "mime-Type": "multipart/form-data",
        // authorization: `${apitoken}`,
      },
    }
  );
  return response;
}

export async function bookHomecarePackage(payload) {
  const response = await axiosCMS.post(
    `/store_homecare_request`,
    // `/store_request`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}

export async function bookPackageTest(payload) {
  console.log("payload", payload)
  const response = await axiosCMS.post(
    `/store_diagnostics_request`,
    // `/store_request`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}
