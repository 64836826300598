import React, { useEffect, useState, lazy, Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { locationReducer } from "./redux/slice/location";
import { getLatLongDetail } from "./actions/get-calls";
import ReactGA from "react-ga4";
import { APP_SUB_FOLDER, site_SEO_META } from "./constants/app-constants";
import LodingComponent from "./component/loding";
const NavBar = lazy(() => import ('./component/navbar/index'));
const Footer = lazy(() => import ('./component/footer/index'));
const Home = lazy(() => import ('./pages/home/index'));
const About = lazy(() => import ('./pages/about'));
const BookingConsult = lazy(() => import ('./pages/booking/index'));
const UserRegister = lazy(() => import ('./pages/register/index'));
const ConsultDoctor = lazy(() => import ('./pages/consult-doctor/consultDoctor2'));
const DectorProfile = lazy(() => import ('./pages/dector-profile/dectorProfile'));
const HopistalDectorProfile = lazy(() => import ('./pages/dector-profile/hospitalDoctorprofile'));
const ClinicsDectorProfile = lazy(() => import ('./pages/dector-profile/clinicsDectorProfile'));
const HomecareService = lazy(() => import ('./pages/homecareService/homecareService'));
const HomecareCategory = lazy(() => import ('./pages/homecareService/homecareCategory'));
const HomecareCategoryPackages = lazy(() => import ('./pages/homecareService/homecareCategoryCompare'));
const HomecarePackageCompare = lazy(() => import ('./pages/homecareService/homecarePackageCompare'));
const HomecareServiceBooking = lazy(() => import ('./pages/package-login/HomecareServiceBooking'))
const ConsultDectorEarlistSlot = lazy(() => import ('./pages/consult-dector-earlist-slot/consultDectorEarlistSlot'))
const BookingConfirmed = lazy(() => import ('./pages/Booking-Confirmed/BookingConfirmed'))
const ConsultHospital = lazy(() => import ('./pages/consult-hopsital/consultHospital'))
const ConsultHopsitalDector = lazy(() => import ('./pages/consult-hopsital/consultHopsitalDector'))
const ConsultClinicsDector = lazy(() => import ('./pages/consult-clinics/consultClinicsDector'))
const BookPackageOrTest = lazy(() => import ('./pages/book-package-or-test'))
const PackageComparison = lazy(() => import ('./pages/package-comparison/packageComparison'))
const PackageBooking = lazy(() => import ('./pages/book-package-or-test/package/packageBooking'))
const PackageBookingConfirmed = lazy(() => import ('./pages/Booking-Confirmed/packageBookingConfirmed'))
const OrderMedicineComponent = lazy(() => import ('./pages/order-medicine/OrderMedicine'))
const CarePackageCategories = lazy(() => import ('./pages/care-package/carePackageCategories'))
const CarePackages = lazy(() => import ('./pages/care-package/carePackages'))
const CareBookingConfirmed = lazy(() => import ('./pages/care-package/careBookingConfirmed'))
const Blog = lazy(() => import ('./pages/blog/blog'))
const BlogDetails = lazy(() => import ('./pages/blog/blog-details'))

const Partners = lazy(() => import ('./pages/partners/partners'))
const TermsAndConditions = lazy(() => import ('./pages/terms-and-conditions/termsAndConditions'))

const PatientDashBoard = lazy(() => import ('./pages/patient-portal/patient-dashboard'))
const PatientMyBooking = lazy(() => import ('./pages/patient-portal/patientMyBooking'))
const PatientMyprescriptions = lazy(() => import ('./pages/patient-portal/patientMyprescriptions'))
const PatientMyDocument = lazy(() => import ('./pages/patient-portal/patientMyDocument'))
const PatientMyReport = lazy(() => import ('./pages/patient-portal/patientMyReport'))


const CarePackageBooking = lazy(() => import ('./pages/care-package/carePackageBooking'))
const TestBooking = lazy(() => import ('./pages/book-package-or-test/test/testBooking'))
const PrivacyPolicy = lazy(() => import ('./pages/privacy-policy/privacyPolicy'))
const TermsOfUse = lazy(() => import ('./pages/terms-of-use/termsOfUse'))
const ContactUs = lazy(() => import ('./pages/contact-us/contactUs'))

const CareerMain = lazy(() => import ('./pages/careers/careermain'))
const CareerSub = lazy(() => import ('./pages/careers/careersub'))
const FAQ = lazy(() => import ('./pages/faq/faq'))
const PurchaseDevice = lazy(() => import ('./pages/purchaseDevice/purchaseDevice'))
const ProfileEdit = lazy(() => import ('./pages/my-profile/profile-edit'))
const ConsultSpecialist = lazy(() => import ('./pages/consult-specialities/consultSpecialist'))
const LandingPage = lazy(() => import ('./pages/landing-page'))
const Insights = lazy(() => import ('./pages/insights/Insight'))
const CarePackageEyeButton = lazy(() => import ('./pages/care-package/CarePackageEyeButton'))
const PackageTestDetail = lazy(() => import ('./pages/book-package-or-test/packageTestEyeButton'))
const PageNotFound = lazy(() => import ('./component/404'))
const MyWalletHistory = lazy(() => import ('./pages/my-wallet/myWallet'))

const BookDoctors = lazy(() => import ('./pages/book-doctor-in-your-location'))

const BookingConsultHospital = lazy(() => import ('./pages/booking/hospitals'))
const BookingConsultSpecialities = lazy(() => import ('./pages/booking/specialities'))
const BookingConsultClinics = lazy(() => import ('./pages/booking/clinics'))
const BookingConsultDoctors = lazy(() => import ('./pages/booking/doctors'))
const DiagnosticProgram = lazy(() => import ('./pages/diagnostic'))

const HomecareServicePage = lazy(() => import ('./pages/Homecare-service-page'))


function App() {
	const dispatch = useDispatch();
	const [status, setStatus] = useState(null);


	if (site_SEO_META == true) {
		// OUR_TRACKING_ID
		ReactGA.initialize([
			{ trackingId: "UA-216817545-1", gaOptions: { name: "Google 3" } },
			{ trackingId: "G-JBMN6QNM88", gaOptions: { name: "Google 4" } },
			{ trackingId: "AW-403912480", gaOptions: { name: "Google Ads" } }
		]);
		// ReactGA.send("pageview");
	}

	// const location = useLocation()
	const getLocation = () => {
		if (!navigator.geolocation) {
			setStatus("Geolocation is not supported by your browser");

		} else {
			setStatus("Locating...");

			navigator.geolocation.getCurrentPosition(
				async (position) => {
					if (position && position.coords) {
						setStatus(null);
						const _locationDetail = await getLatLongDetail(
							position.coords.latitude,
							position.coords.longitude
						);

						if (
							_locationDetail &&
							_locationDetail.data &&
							_locationDetail.data.results
						) {
							dispatch(locationReducer(_locationDetail.data.results[0]));
							// console.log("_locationDetail.data.results[0]", _locationDetail.data.results[0])
						}
					}
				},
				() => {
					setStatus("Unable to retrieve your location");
					// showToast("Please Select your location")
				}
			);
		}
	};

	useEffect(() => {
		getLocation();

	}, []);
	
	function showToast(str) {
		toast.success(str, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false
		});
	}
	return (
		<React.Fragment>
			<Router>
				
				<Suspense fallback={<LodingComponent className="mhc-Loding-minHeight" />}>
				{!window.location.pathname.includes('show_landingpage') && <NavBar />}
				<Routes>
					<Route path={`${APP_SUB_FOLDER}/`} element={<Home />} />	
					<Route path={`${APP_SUB_FOLDER}/about-us`} element={<About />} />
					<Route path={`${APP_SUB_FOLDER}/contact-us`} element={<ContactUs />} />
					<Route path={`${APP_SUB_FOLDER}/insights`} element={<Insights />} />
					<Route path={`${APP_SUB_FOLDER}/user-register`} element={<UserRegister />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult`} element={<Navigate to={`${APP_SUB_FOLDER}/book-consult/hospitals`} />} />
					{/* <Route path={`${APP_SUB_FOLDER}/book-consult/hospitals`} element={<BookingConsult />} /> */}
					<Route path={`${APP_SUB_FOLDER}/book-consult/hospitals`}  element={<BookingConsultHospital />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/hospitals/:slug/:hId`} element={<ConsultHospital />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/hospitals/:hospitalSlug/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />
					{/* <Route path={`${APP_SUB_FOLDER}/book-consult/specialities`} element={<BookingConsult />} /> */}
					<Route path={`${APP_SUB_FOLDER}/book-consult/specialities`} element={<BookingConsultSpecialities />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/specialities/:specialistName/:sId`} element={<ConsultSpecialist />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/clinics`}  element={<BookingConsultClinics />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/doctors`} element={<BookingConsultDoctors />} />
					{/* <Route path={`${APP_SUB_FOLDER}/book-consult/clinics`} element={<BookingConsult />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/doctors`} element={<BookingConsult />} /> */}
					<Route path={`${APP_SUB_FOLDER}/hospital-consult/:hId`} element={<ConsultHospital />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/consult-doctor/:hospitalName/:Id/:sId`} element={<ConsultDoctor />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/clinics/:specilityName/:Id/:sId`} element={<ConsultDoctor />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/hospitals/:hospitalSlug/:specialitySlug/:Id/:sId`} element={<ConsultDoctor />} />
					<Route path={`${APP_SUB_FOLDER}/consult-doctor/:hospitalSlug/:specialitySlug/:doctorSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/consult-doctor-earlist-slot/:Id/:sId`} element={<ConsultDectorEarlistSlot />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/consult-doctor-earlist-slot/:sId`} element={<ConsultDectorEarlistSlot />} />

					<Route path={`${APP_SUB_FOLDER}/book-consult/specialities/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/clinics/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/doctors/:doctorNameSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/book-consult/:hospitalSlug/:doctorNameSlug/:dId/:hId/:sId/:cId`} element={<DectorProfile />} />

					<Route path={`${APP_SUB_FOLDER}/homecare-services`} element={<HomecareService />} />
					<Route path={`${APP_SUB_FOLDER}/homecare-service-category/:categoryName/:categoryId`} element={<HomecareCategory />} />
					<Route path={`${APP_SUB_FOLDER}/homecare-services/:slug/:categoryId`} element={<HomecareCategory />} />
					{/* <Route path={`${APP_SUB_FOLDER}/homecare-services/:pageSlug`} element={<HomecareServicePage />} /> */}

					<Route path={`${APP_SUB_FOLDER}/homecare-service-packages/:slug/:hospitalSlug/:categoryId/:hospitalId`} element={<HomecareCategoryPackages />} />
					<Route path={`${APP_SUB_FOLDER}/homecare-service-package-compare/:hospitalId/:compare1/:compare2/:categoryName/:subCatName`} element={<HomecarePackageCompare />} />
					{/* <Route path={`${APP_SUB_FOLDER}/homecare-service-booking/:categoryName/:subCatName/:categoryId/:hospitalId`} element={<HomecareServiceBooking />} /> */}
					<Route path={`${APP_SUB_FOLDER}/homecare-services/:categoryName/:serviceProviderName/:serviceName/:categoryId/:sPId/:sId`} element={<HomecareServiceBooking />} />
					<Route path={`${APP_SUB_FOLDER}/Booking-confirmed/:Id`} element={<BookingConfirmed />} />
					<Route path={`${APP_SUB_FOLDER}/hospital-dector-profile`} element={<HopistalDectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/consult-hopsital-dector`} element={<ConsultHopsitalDector />} />
					<Route path={`${APP_SUB_FOLDER}/clinics-dector-profile`} element={<ClinicsDectorProfile />} />
					<Route path={`${APP_SUB_FOLDER}/consult-clinics-dector`} element={<ConsultClinicsDector />} />
					<Route path={`${APP_SUB_FOLDER}/specialist/:pageSlug`} element={<BookDoctors />} />
					<Route path={`${APP_SUB_FOLDER}/diagnostics/:pageSlug`} element={<DiagnosticProgram />} />
					<Route path={`${APP_SUB_FOLDER}/book-package-or-test`} element={<Navigate to={`${APP_SUB_FOLDER}/book-package-or-test/packages`} />} />
					<Route path={`${APP_SUB_FOLDER}/book-package-or-test/packages`} element={<BookPackageOrTest />} />
					<Route path={`${APP_SUB_FOLDER}/book-package-or-test/test`} element={<BookPackageOrTest />} />
					<Route path={`${APP_SUB_FOLDER}/package-comparison/:categoryName/:compare1/:compare2`} element={<PackageComparison />} />
					<Route path={`${APP_SUB_FOLDER}/book-package-or-test/packages/:slug/package-booking`} element={<PackageBooking />} />
					<Route path={`${APP_SUB_FOLDER}/package-booking`} element={<PackageBooking />} />
					<Route path={`${APP_SUB_FOLDER}/package-booking/:slug/:dId`} element={<PackageTestDetail />} />
					<Route path={`${APP_SUB_FOLDER}/book-package-or-test/test/booking`} element={<TestBooking />} />
					<Route path={`${APP_SUB_FOLDER}/package-booking-confirm`} element={<PackageBookingConfirmed />} />

				
					<Route path={`${APP_SUB_FOLDER}/order-medicine`} element={<OrderMedicineComponent />} />
					
					<Route path={`${APP_SUB_FOLDER}/care-package-categories`} element={<CarePackageCategories />} />
					<Route path={`${APP_SUB_FOLDER}/care-packages/:categoryName/:categoryId`} element={<CarePackages />} />
					<Route path={`${APP_SUB_FOLDER}/care-package-comparison/:categoryName/:compare1/:compare2`} element={<PackageComparison />} />
					<Route path={`${APP_SUB_FOLDER}/care-package-booking/:categoryName/:subCategoryName`} element={<CarePackageBooking />} />
					<Route path={`${APP_SUB_FOLDER}/care-booking-confirmed`} element={<CareBookingConfirmed />} />
					<Route path={`${APP_SUB_FOLDER}/care-package-info`} element={<CarePackageEyeButton />} />

					<Route path={`${APP_SUB_FOLDER}/health-blogs`} element={<Blog />} />
					{/* <Route path="/blog-details/:title" element={<BlogDetails />} /> */}
					<Route path={`${APP_SUB_FOLDER}/health-blogs/:slug`} element={<BlogDetails />} />
					<Route path={`${APP_SUB_FOLDER}/partners`} element={<Partners />} />
					<Route path={`${APP_SUB_FOLDER}/terms-of-use`} element={<TermsOfUse />} />
					<Route path={`${APP_SUB_FOLDER}/privacy-policy`} element={<PrivacyPolicy />} />
					<Route path={`${APP_SUB_FOLDER}/terms-and-conditions`} element={<TermsAndConditions />} />
					<Route path={`${APP_SUB_FOLDER}/purchase-device`} element={<PurchaseDevice />} />


					{/* show_landingpage/full-body-check-ups-redcliffe */}
					<Route path={`${APP_SUB_FOLDER}/show_landingpage/:slug`} element={<LandingPage />} />
					<Route path={`${APP_SUB_FOLDER}/career`} element={<CareerMain />} />
					<Route path={`${APP_SUB_FOLDER}/career-sub/:slug`} element={<CareerSub />} />
					<Route path={`${APP_SUB_FOLDER}/faq`} element={<FAQ />} />

					{/* dashboard Link */}
					<Route path={`${APP_SUB_FOLDER}/my-dashboard`} element={<PatientDashBoard />} />
					<Route path={`${APP_SUB_FOLDER}/my-bookings`} element={<PatientMyBooking />} />
					<Route path={`${APP_SUB_FOLDER}/my-profile`} element={<ProfileEdit />} />
					<Route path={`${APP_SUB_FOLDER}/patient-my-prescription`} element={<PatientMyprescriptions />} />
					<Route path={`${APP_SUB_FOLDER}/patient-my-document`} element={<PatientMyDocument />} />
					<Route path={`${APP_SUB_FOLDER}/patient-my-report`} element={<PatientMyReport />} />
					<Route path={`${APP_SUB_FOLDER}/my-wallet`} element={<MyWalletHistory />} />
					<Route path={`${APP_SUB_FOLDER}*`} element={<PageNotFound />} />
					<Route path={`${APP_SUB_FOLDER}/404`} element={<PageNotFound />} />
					{/* <Route path={`${APP_SUB_FOLDER}/*`} element={<Navigate to={`${APP_SUB_FOLDER}/404`} />} /> */}
				</Routes>
		   
				<Footer />
				</Suspense>
			</Router>
			<ToastContainer />
		</React.Fragment>
	);
}

export default App;
