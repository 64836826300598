import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = null;
export const reschedule = createSlice({
  name: "reschedule",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setRescheduleReducer: (state, action) => {
      state.value = action.payload;
    },

    clearRescheduleReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setRescheduleReducer, clearRescheduleReducer } = reschedule.actions;

export default reschedule.reducer;