import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const bookingSource = createSlice({
  name: "bookingSource",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setBookingSourceReducer: (state, action) => {
      state.value = action.payload;
    },
    clearBookingSourceReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setBookingSourceReducer, clearBookingSourceReducer } = bookingSource.actions;
export default bookingSource.reducer;
