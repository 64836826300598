import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const delivery = createSlice({
  name: "delivery",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setDeliveryReducer: (state, action) => {
      state.value = action.payload;
    },

    clearDeliveryReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setDeliveryReducer, clearDeliveryReducer } = delivery.actions;
export default delivery.reducer;
