import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const callToken = createSlice({
  name: "callToken",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setCallTokenReducer: (state, action) => {
      state.value = action.payload;
    },
    clearCallTokenReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCallTokenReducer, clearCallTokenReducer } = callToken.actions;
export default callToken.reducer;