import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedCity = createSlice({
  name: "selectedCity",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    selectedCityReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { selectedCityReducer } = selectedCity.actions;
export default selectedCity.reducer;
