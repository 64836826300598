import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const location = createSlice({
  name: "location",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    locationReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { locationReducer } = location.actions;
export default location.reducer;
