import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedMemberId = createSlice({
    name: "selectedMemberId",
    initialState: {
        value: initialStateValue,
    },
    reducers: {
        setSelectedMemberIdReducer: (state, action) => {
            state.value = action.payload;
        },

        clearSelectedMemberIdReducer: (state) => {
            state.value = initialStateValue;
        },
    },
});
export const { setSelectedMemberIdReducer, clearSelectedMemberIdReducer } = selectedMemberId.actions;

export default selectedMemberId.reducer;
