import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const programHospital = createSlice({
  name: "programHospital",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setProgramHospitalReducer: (state, action) => {
      state.value = action.payload;
    },
    clearProgramHospitalReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
// export const { setProgramHospitalReducer, clearProgramHospitalReducer } = programHospital.actions;
export const { setProgramHospitalReducer, clearProgramHospitalReducer } = programHospital.actions;
export default programHospital.reducer;
