import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedMembers = createSlice({
    name: "selectedMembers",
    initialState: {
        value: initialStateValue,
    },
    reducers: {
        setSelectedMembersReducer: (state, action) => {
            state.value = action.payload;
        },

        clearSelectedMembersReducer: (state) => {
            state.value = initialStateValue;
        },
    },
});
export const { setSelectedMembersReducer, clearSelectedMembersReducer } = selectedMembers.actions;

export default selectedMembers.reducer;
