import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const bookingStatusHistory = createSlice({
  name: "booking_status",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setBookingStatusHistory: (state, action) => {
      state.value = action.payload;
    },

    clearHackageOrderHistory: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setBookingStatusHistory, clearBookingStatusHistory } = bookingStatusHistory.actions;
export default bookingStatusHistory.reducer;
