import axios from "axios";
import {apitoken, clinic_id} from "../constants/app-constants";
import axiosClient from "./api-handlers";
import axiosCMS from "./cms-api-handlers";
import axiosMHClient from "./second-api-handlers";

const httpHeaderWithPrimaryToken = {
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"aggregatorType": "market_place",
		"apitoken": apitoken,
	},
};

const httpHeaderWithSecondaryToken = {
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"aggregatorType": "opd_emr",
		"apitoken": apitoken,
	},
};

export async function getHeadingFromCMS(page, section) {
	const response = await axiosCMS.get(`show_headings/${page}/${section}`);
	return response;
}

export async function getImageFromCMS(page, section) {
	const response = await axiosCMS.get(`imgs/${page}/${section}`);
	return response;
}

export async function getTextFromCMS(page, section) {
	const response = await axiosCMS.get(`txts/${page}/${section}`);
	return response;
}

export async function countries() {
	const response = await axiosClient.get(`/template-parts/order-medicine/countries.json`);
	return response;
}

export async function indianCities(diagnostic, page) {
	const response = await axiosClient.get(`/patients/web/v2/patients/indian-city?is_diagnostic=${diagnostic}&page=${page}`);
	return response;
}
export async function searchindianCities(search) {
	const response = await axiosClient.get(`/patients/web/v2/patients/get-indian-city?search=${search}`, httpHeaderWithPrimaryToken);
	return response;
}
export async function getPurchaseDevice(page, JWT) {
	const response = await axiosClient.get(`/patients/android/v2/get-ecommerce-product?page=${page}`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `${JWT}`,
		},
	});
	return response;
}

export async function configurations() {
	const response = await axiosClient.get(`/patients/web/v2/configurations`);
	return response;
}

export async function hospitalByCity(payload) {
	const response = await axiosClient.get(`/patients/web/v2/hospitals-by-city?${payload}`);
	return response;
}

export async function getPicodeBylatLong(payload) {
	const response = await axiosClient.get(`/patients/web/v2/maps/geolatlong${payload}`, httpHeaderWithPrimaryToken);
	return response;
}

export async function specialitiesListByHospital(id) {
	const response = await axiosClient.get(`/patients/web/v2/specialities?hospital_id=${id}`);
	return response;
}
export async function getAllSpecialities(page) {
	let url = `/patients/web/v2/patients/all-specialities?page=${page}`
	const response = await axiosClient.get(url, httpHeaderWithPrimaryToken);
	return response;

}

export async function getAllDoctors(page, keyword, type) {
	let url = `/patients/web/v2/specialities/doctors/all-doctors?page=${page}&keyword=${keyword}&type=${type}`
	const response = await axiosClient.get(url);
	return response;
}

export async function getAllDoctorsBySpecialitiesId(specialId, page) {
	let url = `patients/web/v2/multispecialities/get-multispecialities/doctors?multispecialities=[${specialId}]&page=${page}`
	// let url = `/patients/web/v2/specialities/${specialId}/doctors?page=${page}`
	const response = await axiosClient.get(url, httpHeaderWithPrimaryToken);
	return response;
}
export async function searchDoctorBySpecility(specialId, keyword = '') {
	let url = `/patients/web/v2/specialities/${specialId}/doctors?keyword=${keyword}`
	const response = await axiosClient.get(url, httpHeaderWithPrimaryToken);
	return response;
}
export async function getAllClinics() {
	let url = `/patients/web/v2/patients/all-clinics`
	const response = await axiosClient.get(url, httpHeaderWithPrimaryToken);
	return response;

}

export async function getHospitals(id) {
	const response = await axiosClient.get(`/patients/web/v2/hospitals/${id}`);
	return response;
}

export async function clinicDistance() {
	const response = await axiosClient.get(`/patients/web/v2/patinets/clinic-distance`);
	return response;
}

export async function getDocAvailability() {
	const response = await axiosClient.get(`/patients/web/v2/doctors/2054/availability`);
	return response;
}

export async function getProfile(JWT) {
	const response = await axiosClient.get(
		`/patients/web/v2/patients/profile`,
		{
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `${JWT}`,
			},
		}
	);
	return response;
}

export async function getProfileWithPayload(payload, token) {
	const response = await axiosClient.get(
		`/patients/web/v2/patients/profile` + payload,
		{
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				'Authorization': `${token}`,
				'aggregatorType': httpHeaderWithPrimaryToken.headers.aggregatorType,
				'apiToken': httpHeaderWithPrimaryToken.headers.apitoken,
			},
		}
	);
	return response;
}

export async function familyMember(familyMember) {
	const response = await axiosClient.get(`/specialities/75/doctors/2057/user/account/${familyMember}`);
	return response;
}

export async function timeslots(time) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/1630/availability-timeslots?${time}`);
	return response;
}

export async function pincodeByCity(city) {
	const response = await axios.get(`https://api.postalpincode.in/postoffice/${city}`);
	return response;
}

export async function indianPincode(pincode, vendor_id) {
	const response = await axiosClient.get(
		vendor_id ? `/patients/web/v2/patients/get-indian-pincode?pincode=${pincode}&vendor_id=${vendor_id}` : `/patients/web/v2/patients/get-indian-pincode?pincode=${pincode}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function getLatLongDetail(lat, long) {
	if (!lat || !long) {
		return;
	}
	const response = await axiosClient.get(`/patients/web/v2/maps/geolatlong/${lat}/${long}`,
		httpHeaderWithPrimaryToken
	);
	return response;
}

export async function getTimeslotByHospital(vendor_id, city_id, visit_date, pincode, locality = "") {
	const response = await axiosClient.get(`/patients/web/v2/patients/lalpath-slots-availability?vendor_id=${vendor_id}&city_id=${city_id}&visit_date=${visit_date}&pincode=${pincode}&zipcode=${pincode}&locality=${locality}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function thyrocareDetail(id, pincode) {
	const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-test-Details?diagnosis_id=${id}&pincode=${pincode}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function thyrocareList(type, category, pincode, page, keyword) {
	keyword = keyword ? "&keyword=" + keyword : type === 2 ? "&category=" + category : "";
	const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-list-products?type=${type}&pincode=${pincode}&page=${page}${keyword}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function testDetails(payload) {
	
	const searchParams = new URLSearchParams(payload);

	const response = await axiosClient.get(`/patients/web/v2/patients/get-test-details?` + payload,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function vendorCities(id) {
	const response = await axiosClient.get(`/patients/web/v2/patients/lalpath-cities?vendor_id=${id}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function getOneMGPincode(pincode) {
	const response = await axiosClient.get(`/patients/ios/v2/website/get-onemgpincode?pincode=${pincode}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function getOrderMedicinePincode(pincode) {
	const response = await axiosClient.get(`/patients/ios/v2/website/get-medicine-order-pincode?pincode=${pincode}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function getHomecareCategory() {
	const response = await axiosCMS.get(`/get_homecare_category_list`);
	return response;
}
export async function getHomecareCategoryList(catId) {
	const response = await axiosCMS.post(`get_homecare_service_list?category_id=${catId}`);
	return response;
}

export async function getHomecareCategoryDetils(serviceId) {
	// const response = await axiosCMS.post(`get_homecare_service_detils?category_id=${catId}&service_id=${serviceId}`);
	const response = await axiosCMS.post(`get_homecare_service_detils?service_id=${serviceId}`);
	return response;
}

export async function getHospitalListingByCat(catId) {
	const response = await axiosClient.get(`/patients/ios/v2/hospitals-by-city?category_id=${catId}&is_homecare=1`);
	return response;
}

export async function packageByHospital(catId, hospitalId, page, type) {
	const response = await axiosClient.get(`/patients/ios/v2/test-list-by-category?category_id=${catId}&hospital_id=${hospitalId}&page=${page}&type=${type}`);
	return response;
}

// export async function packageDetails() {
// 	const response = await axiosClient.get(`/patients/ios/v2/test/hospital_id/28/test/75`);
// 	return response;
// }

export async function packageAvailability(hospital_id, test_id) {
	const response = await axiosClient.get(`/patients/ios/v2/test?hospital_id=28&test_id=75`, httpHeaderWithPrimaryToken);
	return response;
}

export async function doctorAvailability(specialId, hospitalId, keyword) {
	keyword = keyword !== "" ? "&keyword=" + keyword : "";
	const response = await axiosClient.get(`/patients/web/v2/specialities/${specialId}/doctors?hospital_id=${hospitalId}${keyword}`);
	return response;
}

export async function doctorImmediateAvailability(specialId, hospitalId) {
	let url = `/patients/web/v2/specialities/${specialId}/immediate-doctors`
	if (hospitalId) {
		url += `?hospital_id=${hospitalId}`
	}
	const response = await axiosClient.get(url);
	return response;
}

export async function specialityInfoById(Id) {
	const response = await axiosClient.get(`/patients/web/v2/specialities/${Id}`);
	return response;
}

export async function doctorProfileById(docId, hospitalId) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/${docId}?hospital_id=${hospitalId}`);
	return response;
}

export async function doctorAvailabilityById(Id) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/${Id}/availability`);
	return response;
}

export async function doctorTimeSlots(docId, hospitalId, date, consultT) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/${docId}/availability-timeslots?hospital_id=${hospitalId}&date=${date}&consult_type=${consultT}`);
	return response;
}

export async function carePackages() {
	const response = await axiosMHClient.get(`/patients/web/v2/special-health-offers`);
	return response;
}
// export async function getPackageDetail(hospitalId, testId) {
// 	const response = await axiosClient.get(`/patients/ios/v2/test/hospital_id/${'1800'}/test/${'1555'}`, httpHeaderWithPrimaryToken);
// 	return response;
// }

export async function getClinicDistance() {
	const response = await axiosMHClient.get(`/patients/web/v2/patinets/clinic-distance`);
	return response;
}
export async function fetchClinicList() {
	const response = await axiosMHClient.get(`/patients/web/v2/specialities?hospital_id=${clinic_id}`);
	return response
}
export async function checkDoctorAvailabilityDate(doctor_id, hospital_id) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/${doctor_id}/availability-dates?hospital_id=${hospital_id}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function checkBookingStatus(bookingId) {
	const response = await axiosClient.get(`/patients/ios/v2/doctors/appointmentStatus/${bookingId}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function clinicSearchAPI(keyword, type) {
	if (keyword === "") return false;
	const response = await axiosMHClient.get(`/patients/web/v2/search?hospital_id=${clinic_id}&keyword=${keyword}`);
	return response;
}
export async function checkBookingStatusPackage(order_id) {
	const response = await axiosMHClient.get(`/patients/web/v2/patients/diagnostics-order-status?ref_order_id=${order_id}`,
	httpHeaderWithPrimaryToken);
	return response;
}
export async function checkBookingStatusCheck(booking_id) {
	const response = await axiosMHClient.get(`/patients/web/v2/doctors/appointmentStatus?booking_id=${booking_id}`,
	httpHeaderWithPrimaryToken);
	return response;
}
// For CMS DATA --------------------------------------------------------------------------------------------------------
export async function fetchHomepageData() {
	const response = await axiosCMS.get(`get_homepage_data`);
	return response
}
export async function fetchHomepageCategoriesData() {
	const response = await axiosCMS.get(`get_homepage_categories`);
	return response
}

export async function fetchPartnerHospitals() {
	const response = await axiosCMS.get(`partner_hospitals`);
	return response
}
export async function getAllBlogs() {
	const response = await axiosCMS.get(`show_allblogs`);
	return response;
}
export async function getPartnerFirstSection() {
	const response = await axiosCMS.get(`imgs/3/1`);
	return response;
}
export async function getAllInsights() {
	const response = await axiosCMS.get(`imgs`);
	return response;
}
export async function getAboutUs() {
	const response = await axiosCMS.get(`show_aboutus`);
	return response;
}
export async function getBoardMembers() {
	const response = await axiosCMS.get(`imgs/2/2`);
	return response;
}
export async function getTeamMembers() {
	const response = await axiosCMS.get(`imgs/2/6`);
	return response;
}
export async function getDoctorReviews() {
	const response = await axiosCMS.get(`txts/2/5`);
	return response;
}

export async function getBannerData() {
	const response = await axiosCMS.get(`imgs/2/1`);
	return response;
}

export async function getInvestors() {
	const response = await axiosCMS.get(`imgs/2/3`);
	return response;
}

export async function getAllFAQs() {
	const response = await axiosCMS.get(`show_faqs`);
	return response;
}

export async function getAllCareer() {
	const response = await axiosCMS.get(`show_landingpage/lp-1`);
	return response;
}

export async function getCareerBanner() {
	const response = await axiosCMS.get(`career_banners`);
	return response;
}

export async function getAllJobListing() {
	const response = await axiosCMS.get(`alljobs_listing`);
	return response;
}

export async function getJobDataBySlug(slug) {
	const response = await axiosCMS.get(`singlejobs_listing/${slug}`);
	return response;
}

export async function getLandingPageBySlug(slug) {
	const response = await axiosCMS.get(`show_landingpage/${slug}`);
	return response;
}

export async function getBlogDetailByTitle(title) {
	const response = await axiosCMS.get(`show_singleblog/${title}`);
	return response;
}

export async function getLatestBlogs() {
	const response = await axiosCMS.get(`show_letestblog`);
	return response;
}

export async function getPopularBlogs() {
	const response = await axiosCMS.get(`show_allblogs`);
	return response;
}

export async function getHealthCareBlogs() {
	const response = await axiosCMS.get(`show_healthblogs`);
	return response;
}

export async function getDownloadURLs() {
	const response = await axiosCMS.get('myhealthcare_downloads');
	return response;
}

export async function getOtherCity(speciality_id) {
	let payload = {'speciality_id': JSON.stringify(speciality_id)}
	const response = await axiosCMS.post(`get_other_city`, payload,{
		// headers: {
		// 	Accept: "application/json",
		// 	"Content-Type": "application/json"
		// },		
	});
	return response;
}

export async function getOtherSpeciality(city_id) {
	let payload = {'city_id': city_id}
	const response = await axiosCMS.post(`get_other_speciality`, payload);
	return response;
}

export async function getOtherCitypackagedata(package_id) {
	let payload = {'package_id':package_id}
	const response = await axiosCMS.post(`get_other_citypackagedata`, payload,{	
	});
	return response;
}

export async function getOtherPackageData(city_id) {
	let payload = {'city_id': city_id}
	const response = await axiosCMS.post(`get_other_packagedata`, payload);
	return response;
}

/// home care serveic
export async function getHomecareCityData(city_id) {	
	let payload = {'city_id':city_id}
	const response = await axiosCMS.post(`get_homecare_cityData`, payload,{	
	});
	return response;
}

export async function getHomecareServiceData(servicname) {
	let payload = {'servicname':servicname}
	const response = await axiosCMS.post(`get_homecare_serviceData`, payload,{	
	});
	return response;
}