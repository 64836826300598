import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const allUsers = createSlice({
    name: "allUsers",
    initialState: {
        value: initialStateValue,
    },
    reducers: {
        setAllUsersReducer: (state, action) => {
            state.value = action.payload;
        },
        clearAllUsersReducer: (state) => {
            state.value = initialStateValue;
        },
    },
});
export const { setAllUsersReducer, clearAllUsersReducer } = allUsers.actions;
export default allUsers.reducer;
