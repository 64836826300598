import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const packageDelivery = createSlice({
  name: "packageDelivery",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setPackageDeliveryReducer: (state, action) => {
      state.value = action.payload;
    },

    clearPackageDeliveryReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setPackageDeliveryReducer, clearPackageDeliveryReducer } = packageDelivery.actions;
export default packageDelivery.reducer;
