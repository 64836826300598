import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedUserCard = createSlice({
    name: "selectedUserCard",
    initialState: {
        value: initialStateValue,
    },
    reducers: {
        setSelectedUserCardReducer: (state, action) => {
            state.value = action.payload;
        },

        clearSelectedUserCardReducer: (state) => {
            state.value = initialStateValue;
        },
    },
});
export const { setSelectedUserCardReducer, clearSelectedUserCardReducer } = selectedUserCard.actions;

export default selectedUserCard.reducer;