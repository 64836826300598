import axios from "axios";
import { mainBaseURL } from "../constants/app-constants";

function getJWTtoken() {
  let token = localStorage.getItem("callKey");
  if (typeof window !== "undefined") {
    return token;
  } else if (token === undefined) {
    return null;
  } else {
    return null;
  }
}

let JWTresponse = getJWTtoken();

const getHeaders = () => {
  if (JWTresponse === null) {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  } else {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JWTresponse}`,
      },
    };
  }
};

let Header = getHeaders();

const axiosMHClient = axios.create({
  baseURL: `${mainBaseURL}`,
});

axiosMHClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

export default axiosMHClient;