import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const packageOrderHistory = createSlice({
  name: "order_history",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setPackageOrderHistory: (state, action) => {
      state.value = action.payload;
    },

    clearHackageOrderHistory: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setPackageOrderHistory, clearHackageOrderHistory } = packageOrderHistory.actions;
export default packageOrderHistory.reducer;
