import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const homeCareImg = createSlice({
  name: "homeCareImg",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    sethomeCareImg: (state, action) => {
      state.value = action.payload;
    },

    clearhomeCareImg: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { sethomeCareImg, clearhomeCareImg } = homeCareImg.actions;

export default homeCareImg.reducer;
