import React, { Component } from 'react'
import LodaingImage from "../../assets/images/loader.gif"
import { APP_SUB_FOLDER } from '../../constants/app-constants'

const Iprops={
    className:''
}
export default class LodingComponent extends React.Component{
    constructor(props=Iprops){
        super(props)
    }
    render(){
        return(
            <React.Fragment>
              <div className={`mhc-Loding ${this.props.className}`}>
                 
                      <img src={LodaingImage} alt="loader" />
               
              </div>
            </React.Fragment>
        )
    }
}
