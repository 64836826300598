import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const memberProfile = createSlice({
  name: "memberProfile",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setMemberProfileReducer: (state, action) => {
      state.value = action.payload;
    },

    clearMemberProfileReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setMemberProfileReducer, clearMemberProfileReducer } = memberProfile.actions;

export default memberProfile.reducer;
