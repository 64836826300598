import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const packageDetail = createSlice({
  name: "packageDetail",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setPackageDetailReducer: (state, action) => {
      state.value = action.payload;
    },

    clearPackageDetailReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setPackageDetailReducer, clearPackageDetailReducer } = packageDetail.actions;
export default packageDetail.reducer;
