import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const switchToken = createSlice({
  name: "switchToken",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setSwitchTokenReducer: (state, action) => {
      state.value = action.payload;
    },
    clearSwitchTokenReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setSwitchTokenReducer, clearSwitchTokenReducer } = switchToken.actions;
export default switchToken.reducer;