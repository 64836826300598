import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const removeWallet = createSlice({
  name: "removeWallet",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setRemoveWalletReducer: (state, action) => {
      state.value = action.payload;
    },
    clearRemoveWalletReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setRemoveWalletReducer, clearRemoveWalletReducer } = removeWallet.actions;
export default removeWallet.reducer;
