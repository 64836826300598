import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedPincode = createSlice({
  name: "selectedPincode",
  initialState: {
    value: initialStateValue,
    
  },
  reducers: {

    selectedPincodeReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selectedPincodeReducer } = selectedPincode.actions;
export default selectedPincode.reducer;
