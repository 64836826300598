import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const vendor = createSlice({
  name: "vendor",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setVendorReducer: (state, action) => {
      state.value = action.payload;
    },
    clearVendorReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setVendorReducer, clearVendorReducer } = vendor.actions;
export default vendor.reducer;
