import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const userProfile = createSlice({
  name: "userProfile",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setUserProfileReducer: (state, action) => {
      state.value = action.payload;
    },

    clearUserProfileReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setUserProfileReducer, clearUserProfileReducer } = userProfile.actions;

export default userProfile.reducer;
