import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const patientDetail = createSlice({
  name: "patientDetail",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setPatientDetailReducer: (state, action) => {
      state.value = action.payload;
    },

    clearPatientDetailReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setPatientDetailReducer, clearPatientDetailReducer } = patientDetail.actions;
export default patientDetail.reducer;
