import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const selectedUser = createSlice({
    name: "selectedUser",
    initialState: {
        value: initialStateValue,
    },
    reducers: {
        setSelectedUserReducer: (state, action) => {
            state.value = action.payload;
        },

        clearSelectedUserReducer: (state) => {
            state.value = initialStateValue;
        },
    },
});
export const { setSelectedUserReducer, clearSelectedUserReducer } = selectedUser.actions;

export default selectedUser.reducer;
